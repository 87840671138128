<template>
    <section :id="cmsBlock.anchorTag" :class="cmsBlock.background" class="component-container">
        <div class="separator" :class="cmsBlock.separatorStyle"></div>
    </section>
</template>

<script>
    export default {
        name: 'Separator',

        props: [
            'cmsBlock'
        ]
    }
</script>

<style lang="scss" scoped>
.component-container {
    width: 100%;
    .grey {
        padding: 0;
    }

    .white{
        padding: 0;
    }

    .dark{
        padding: 0;
        .separator{
            background: white;
        }
    }

    .separator {
        height: 2px;
        background: $timberGreen;
    }

    .full_width {
        margin: 0 auto;
        width: calc(100% -  2* getSpacing('padding-default-horizontal', 'desktop'));
        max-width: $container-width;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
            width: calc(100% -  2* getSpacing('padding-default-horizontal', 'tablet'));
        }
        @include breakpoint('mobile') {
            padding: 0 getSpacing('padding-default-horizontal', 'mobile');
            width: calc(100% -  2* getSpacing('padding-default-horizontal', 'mobile'));
        }
    }
}
</style>
